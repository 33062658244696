<template>
  <div class="container">
    <p>
      <a class="mail-link" href="mailto:info@ftwconsulting.se">info@ftwconsulting.se</a>
    </p>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.container {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  backdrop-filter: brightness(0.3);
  min-width: 400px;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: 20%;
  border-radius: 10px;
  padding: 25px;
}

@media (max-width: 500px) {
  .container {
    box-sizing: border-box;
    min-width: auto;
    width: 300px;
    margin: 0 auto;
  }
}

img#logo {
  max-width: 400px;
}

p {
  margin: 0;
}

a.mail-link,
a.mail-link:active,
a.mail-link:visited {
  padding: 10px;
  color: white;
  position: relative;
  text-decoration: none;
}

a.mail-link::before {
  content: '';
  position: absolute;
  right: 10px;
  height: 4px;
  border-radius: 4px;
  background-color: white;
  bottom: 0;
  left: 10px;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform .3s ease-in-out;
}

@media (hover: hover) {
  a:hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }
}
</style>
